import React from "react";

import { Container, SEOPage, Breadcrumb, Maps, ContactForm } from "../components";

import { StyledPage, Grid2 } from "../components/css";

export default function Contact() {

  //On page SEO
  const SEO = {
    entity: "page",
    title: "Contact us",
    keywords: `investments, tenant representation, sales, leasing`,
    description: `No service is out of our skill set.`,
    url: `/services`,
  }

  return (
    <Container page="content" breadcrumbs={true} breadData={{ type: "content", title: SEO.title }}>

      {/* Page meta data (title, description, etc.)*/}
      <SEOPage
        entity={SEO.entity}
        title={SEO.title}
        keywords={SEO.keywords}
        description={SEO.description}
        url={SEO.url}
      />

      <div>
        <Breadcrumb show={true} data={{ type: "content", title: SEO.title, image: "https://res.cloudinary.com/barrow/image/upload/f_auto/Barrow_page_headers-group_desktop_cxvqpy.jpg" }} />

        <StyledPage>

          <h1>Contact us</h1>

          <Grid2>
            <Grid2>
              <div>
                <h5>Address</h5>
                <p>{process.env.GATSBY_COMPANY_ADDRESS}</p>
                <p>{process.env.GATSBY_COMPANY_SUBURB}</p>
                <p>{process.env.GATSBY_COMPANY_CITY}</p>
                <p>{process.env.GATSBY_COMPANY_PROVINCE}</p>
              </div>
              <div>
                <h5>Telephone</h5>
                <p>{process.env.GATSBY_COMPANY_TEL_1}</p>
                {/* <h5>Email</h5>
                <p>{process.env.GATSBY_COMPANY_EMAIL}</p> */}
              </div>
            </Grid2>
            <div>
              <Maps lat={parseFloat(process.env.GATSBY_COMPANY_LATITUDE)} lng={parseFloat(process.env.GATSBY_COMPANY_LONGITUDE)} />
            </div>
          </Grid2>

          <ContactForm title={`Contact us using the form below`} subTitle={`We will get back to you shortly`} data={``} />

        </StyledPage>

      </div>

    </Container>
  )
}